export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "adustFontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adust Font Size"])},
        "fontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font Size"])}
      },
      "zh": {
        "adustFontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调整字体大小"])},
        "fontSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字体大小"])}
      }
    }
  })
}
