export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "changeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Color"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change foreground color."])},
        "confirmToReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation requires reloading the page, do you confirm to continue ?"])}
      },
      "zh": {
        "changeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改前景色"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改前景色，以适应不同的光线环境"])},
        "confirmToReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作需要将要重新加载页面，是否继续？"])}
      }
    }
  })
}
