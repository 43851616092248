export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot access the following url via websocket protocol: "])},
        "commonProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refer to help on kuboard.cn"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm to retry ?"])}
      },
      "zh": {
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能通过 websocket 协议访问如下地址："])},
        "commonProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看问题原因及解决办法"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认重试？"])}
      }
    }
  })
}
