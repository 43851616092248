export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "findInTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find in terminal"])},
        "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
        "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String"])},
        "backward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find backward"])},
        "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find forward"])},
        "pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern"])},
        "caseSensitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case sensitive"])},
        "wholeWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole worlds"])},
        "isHit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hit"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])}
      },
      "zh": {
        "findInTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在终端中查找"])},
        "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查 找"])},
        "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字符串"])},
        "backward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向后查找"])},
        "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向前查找"])},
        "pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正则表达式"])},
        "caseSensitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大小写敏感"])},
        "wholeWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完整单词"])},
        "isHit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否命中"])},
        "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到"])}
      }
    }
  })
}
